* {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  color: white;
}

.navbar {
  position: fixed;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 10%;
  margin: 0 auto;
  background-color: rgba(15, 17, 23, 0.9);
  z-index: 9999;
}

.navbar-logo-wrap {
  padding: 0 !important;
}

.w-inline-block {
  max-width: 60%;
  display: inline-block;
}

.navbar img {
  width: 120px;  
  display: inline-block;
  vertical-align: middle;
}

.navbar .links {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 0.6rem 1rem;
  font-size: 16px;
}

.get-started-button {
  font-family: 'Quicksand';
  font-weight: bold;
  color: black;
  background-color: #91e6ff;
  border-radius: 1.25rem;
  transition: background-color .3s cubic-bezier(.912, -.005, .006, .989);
}

.get-started-button:hover {
  color: black;
  background-color: #5ec1df;
}

.button {
  background-color: black;
}

.navbar .link-block {
  transition: opacity 0.3s, color 0.3s;
}

.navbar .link-block:hover {
  opacity: 0.8; 
  color: #000; 
}

.navbar .w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.navbar .icon-1x1-medium {
  width: 2rem;
  height: 2rem;
  color: #596177;
  margin-top: -5px;
  margin-left: 10px;
  margin-right: -15px;
}

@media (max-width: 790px) {
  .desktop-menu {
    display: none!important;
  }

  .navbar {
    border-bottom: 1px solid #91e6ff;
  }
}

@media (max-width: 1000px) {
  .navbar {
    padding: 10px 30px;
  }
}

@media (min-width: 791px) {
  .mobile-menu {
    display: none!important;
  }
}

.mobile-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu-logo-mobile {
  width: 100px;
  margin-top: 0px;
}

.mobile-menu-toggle {
  cursor: pointer;
}

.mobile-links {
  position: absolute;
  top: 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  left: 0;
  background-color: rgba(15, 17, 23, 1);
  font-family: 'Quicksand';
  font-weight: bold;
}

.links a {
  font-family: 'Quicksand';
  font-weight: bold;
}

.mobile-links a {
  border-bottom: 1px solid #91e6ff;
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.get-started-button-mobile {
  color: black;
  background-color: #91e6ff;
  border-radius: 1.25rem;
  transition: box-shadow .3s cubic-bezier(.912, -.005, .006, .989);
  margin: 1rem 0!important;
  max-width: auto!important;
  width: auto!important;
  font-family: 'Quicksand';
}

.mobile-menu-icon {
  max-width: 30px!important;
  margin-top: 10px;
}

.desktop-menu {
  width: 100%;
  display: flex;
}

